<template>
    <div class="px-3 py-2" :style="`direction:`+lang.dir">
        <v-form
            ref="forinv"
            lazy-validation
        >
        <b-sidebar id="add_BillQuotation" aria-labelledby="sidebar-no-header-title" no-header backdrop width="80rem" style="direction:ltr" right shadow >
    <template #default="{ hide }">
      <div class="m-1 text-start" :style="`direction:rtl`">
        <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
          <span>{{lang.add_quotation}}</span>
        </div>
        <div @click="hide" id="closeBilDil" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
          <span>{{lang.close}}</span>
      </div>
    </div>
            <v-divider></v-divider>
        <div class="px-3 py-2" :style="`direction:`+lang.dir+``">
            <div class="backBlack text-start p-1">اختر المنتج</div>
            <v-simple-table>
                <thead>
                    <tr>
                        <th class="backBlack text-center" style="width:40px">#</th>
                        <th class="backBlack text-center">{{ lang.decription }}</th>
                        <th class="backBlack text-center" style="width:80px">{{ lang.price }}</th>
                        <th class="backBlack text-center" style="width:80px">المواصفات</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in prod" :key="index">
                        <td><b-form-checkbox @change="upConds()" v-model="prod[index].is_active" /></td>
                        <td style="padding-bottom:15px !important;">
                            {{ prod[index].description }}
                            <v-row style="display:none" :id="`offer_det_${index}`">
                                <v-col cols="12" md="4" v-if="prod[index].progDetails.length != 0">
                                    <small>مواصفات البرنامج</small>
                                    <b-list-group>
                                        <b-list-group-item
                                        v-for="(pro,indx) in prod[index].progDetails" :key="indx"
                                        >{{pro}}</b-list-group-item>
                                    </b-list-group>
                                </v-col>
                                <v-col cols="12" md="4" v-if="prod[index].comDetails.length != 0">
                                    <small>مواصفات الجهاز</small>
                                    <b-list-group>
                                        <b-list-group-item
                                        v-for="(pro,indx) in prod[index].comDetails" :key="indx"
                                        >{{pro}}</b-list-group-item>
                                    </b-list-group>
                                </v-col>
                                <v-col cols="12" md="4" v-if="prod[index].printerDetails.length != 0">
                                    <small>مواصفات الطابعة</small>
                                    <b-list-group>
                                        <b-list-group-item
                                        v-for="(pro,indx) in prod[index].printerDetails" :key="indx"
                                        >{{pro}}</b-list-group-item>
                                    </b-list-group>
                                </v-col>
                            </v-row>
                        </td>
                        <td>{{ prod[index].price }}</td>
                        <td class="backRed" style="border:2px solid #fff;text-align:center" @click="showDet(index)"><a noref  >المواصفات</a></td>
                    </tr>
                </tbody>
            </v-simple-table>
        </div>
        <div class="px-3 py-2" :style="`direction:`+lang.dir+``">
            <div class="backBlack text-start p-1">الشروط والاحكام</div>
            <v-simple-table v-for="(item,index) in TermsCond" :key="index">
                <thead>
                    <tr>
                        <th class="backBlack" colspan="2">{{ item.title }}</th>
                    </tr>
                    <tr>
                        <th class="backBlack text-center" style="width:40px;">#</th>
                        <th class="backBlack text-start">الشرط</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(itm,indx) in item.rows" :key="indx">
                        <td><b-form-checkbox v-model="TermsCond[index].rows[indx].is_active" /></td>
                        <td style="padding-bottom:15px !important;">
                            {{ itm.description }}
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </div>
    </template>
        <template #footer="" class="shadow">
        <div class="d-flex text-light align-items-center px-3 py-2">
          <b-button type="button" @click="addQuotation()" variant="success" class="ma-2" style="width:100px;">{{lang.add}}</b-button>
        </div>
      </template>
        </b-sidebar>
        </v-form>
        <vue-snotify></vue-snotify>
      </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify';
export default{
    components: {},
    data() {
        return {
            customerid: 0,
            prod: [],
            mainCond: [],
            TermsCond: [],
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
    },
    created() {
        this.getInvSettings();
        
        // document.getElementById('cCom').click()
    },
    methods: {
        addQuotation()
        {
            const post = new FormData();
            post.append("type", "addBillQuot")
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append("data[customerid]",this.customerid)
            post.append("data[prod]",JSON.stringify(this.prod))
            post.append("data[TermsCond]",JSON.stringify(this.TermsCond))
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                if(response && response.data){
                    document.getElementById('closeBilDil').click();
                    this.$parent.getCustomerInfo();
                    let message = "تمت الاضافة بنجاح";
                    this.$snotify.error(message, this.$parent.lang.alert, {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.$parent.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                }
            })

        },
        upConds()
        {
            
            this.TermsCond.splice(0,this.TermsCond.length);

            // this.TermsCond = this.mainCond
            for(let k=0;k<this.mainCond.length;k++){
                this.TermsCond.push(this.mainCond[k])
            }

            for(let i=0;i<this.prod.length;i++){
                if(this.prod[i].is_active){
                    for(let j=0;j<this.prod[i].conds.length;j++){
                        this.TermsCond.push(this.prod[i].conds[j])
                    }
                }
            }
            
        },
        showDet(index){
            const dc = document.getElementById('offer_det_'+index);
            let showit = true;
            if(dc.style.display == 'none')
            {
                //
            }else{
                showit = false
            }
            const dr = document.querySelectorAll('[id^="offer_det_"]');
            for(let i=0;i<dr.length;i++){
                dr[i].style.display='none';
            }
            
            if(showit)
            {
                dc.style.display='';
            }
        },
        reset(item){
            this.customerid = item.id
            this.getInvSettings();
        },

        getInvSettings() {
            const post = new FormData();
            post.append('type','getInvSettings');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[all]','all');
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    this.prod = res.results.data.prod;
                    this.mainCond = res.results.data.mainCond;
                    this.upConds();
                }
            )
        },

    },
}
</script>
<style>

</style>